<template>
  <div>
    <v-dialog v-model="loading" hide-overlay persistent width="300">
      <v-card color="primary" dark>
        <v-card-text>
          Loading
          <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
    <bfc-bar></bfc-bar>
    <admin-menu></admin-menu>
    <v-card flat class="py-12"> </v-card>
    <v-card>
      <v-card-title> </v-card-title>
      <v-card-text>
        <v-row>
   
          <v-col cols="6" sm="6">
            <!--v-date-picker v-model="dates" range></!--v-date-picker-->
            <v-menu
              ref="start"
              v-model="start"
              :close-on-content-click="false"
              :return-value.sync="dates"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  v-model="dateRangeText"
                  label="Select Date"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker v-model="dates" range no-title scrollable>
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="start = false"> Cancel </v-btn>
                <v-btn text color="primary" @click="$refs.start.save(dates)"> OK </v-btn>
              </v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="12" sm="12"
            ><v-btn color="secondary" @click="searchReport"> Search</v-btn></v-col
          >
        </v-row>
        <v-row>
          <v-col cols="12" md="12" sm="12">
            <!-- <v-data-table
              :headers="headers"
              :items="reportTable"
              :keys="id"
              v-if="reportTable.length > 0"
            >
            </v-data-table> -->
            <v-simple-table fixed-header
              ><template>
                <thead>
                  <tr>
                    <th class="text-center">No.</th>
                    <th class="text-center">Date</th>
                    <th class="text-center">Time</th>
                    <th class="text-center">Case No.</th>
                    <th class="text-center">Inspector</th>
                    <th class="text-center">Commission (THB)</th>
                  </tr>
                </thead>
                <tbody id="financeReport">
                  <tr v-for="(item, index) in reportTable" :key="index">
                    <td class="text-left">{{ index + 1 }}</td>
                    <td class="text-left">{{ item.date }}</td>
                    <td class="text-left">{{ item.time }}</td>
                    <td class="text-left">{{ item.runNo }}</td>
                    <td class="text-left">{{ item.inspector_name }}</td>
                    <td class="text-right">{{ item.commission }}</td>
                  </tr>
                </tbody>
                <tfoot>
                  <td class="text-left"></td>
                  <td class="text-left"></td>
                  <td class="text-left"></td>
                  <td class="text-left"></td>
                  <td class="text-right">Summary</td>
                  <td class="text-right">{{ sumAmount }}</td>
              </tfoot></template>
            </v-simple-table>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import SystemBar from "../../library/system-bar/system-bar";
import AdminMenu from "../menubar/menubar";
import axios from "axios";
import { mapGetters, mapActions } from "vuex";
export default {
  components: {
    "bfc-bar": SystemBar,
    "admin-menu": AdminMenu,
  },
  data() {
    return {
      url: process.env.VUE_APP_LOYALTY_API_URI,
      custId: process.env.VUE_APP_OGF_CUST_ID,
      feedbackCaseNo: "",
      feedbackDialog: false,
      feedbackCaseId: "",
      toggle_exclusive: 0,
      start: false,
      inspector: "",
      dates: [], //new Date().toISOString().substring(0, 10)
      filterName: "",
      loading: false,
      cases: [],
      reportTable: [],
      headers: [
        {
          text: "Date",
          value: "createdAt",
        },
        { text: "Time", value: "time" },
        {
          text: "Case No.",
          value: "caseNo",
        },
        {
          text: "Inspector Name",
          value: "inspector_name",
        },
        {
          text: "Amount (THB)",
          value: "commission",
        },

      ],
    };
  },
  computed: {
    ...mapGetters({}),
    sumAmount() {
      return this.reportTable.length > 0
        ? this.reportTable
            .map((item) => item.commission)
            .reduce((sum, current) => {
              //  console.log(sum + " " + current);
              return (parseFloat(sum) + parseFloat(current)).toFixed(2);
            })
        : 0;
    },
    
    dateRangeText() {
      console.log(this.dates);
      if (this.dates != "") {
        return this.dates.join(" ~ ");
      } else {
        return "";
      }
    },
  },
  beforeMount(){
    this.inspector = this.$cookies.get("Profile").inspector[0];
    console.log(this.inspector);  
  },
  mounted() {
    // this.init();
    //this.reportTable = [];
  },
  methods: {
    ...mapActions({
      //setAdminCasesList: "BFC/setAdminCasesList",
    }),
    async searchReport() {
      console.log("initial data.....");
      this.loading = true;
      try {
        await axios
          .post(
            this.url + "/api/bfc/v1/report/inspector/report",
            {
              inspector: this.inspector._id,
              _filter: this.filterName,
              dateRange: this.dates,
            }, // need to change
            {
              headers: {
                // Overwrite Axios's automatically set Content-Type
                "Content-Type": "application/json",
              },
            }
          )
          .then(async (response) => {
            // console.log(response);
            console.log(response.data);
            if (response.data.statusCode == 0) {
              this.cases = response.data.data;
              this.reportTable = this.cases
               
                .map((item) => {
                  //console.log(item.updatedAt.toString());
                  console.log(item);


                  let inspectorInfo = {
                      firstname : "",
                      lastname : "",
                    }
                  if(item.inspectorId == null){
                      item.inspectorId = inspectorInfo;
                    }

                  // if (typeof _package.text !== "undefined") {
                  //   selectPackage = _package.text;
                  // }

                  // if (typeof item.package.text !== "undefined") {
                  //   selectPackage = item.package.text;
                  // }

                  
                  return {
                    id: item._id,
                    date: item.updatedAt.toString().substring(0, 10),
                    time: item.updatedAt.toString().substring(19, 11),
                    caseNo: item.caseNo,
                    runNo: item.runNo,
                    inspector_name: item.inspectorId.memberId.firstname + " " + item.inspectorId.memberId.lastname,
                    commission: item.commission,

                  };
                });
              //console.log(this.reportTable);
              //this.setAdminCasesList(this.cases);
            } else {
              // show error
              console.log("error response");
            }
          });
        console.log(this.cases);
      } catch (e) {
        console.log(e);
      }
      this.loading = false;
    },

    prepareData() {
      this.reportTable = this.cases
        .filter((item) => {
          return item// item.inspectorId != null;
        })
        .map((item) => {
          //console.log(item.updatedAt.toString());
          console.log(item);


          let inspectorInfo = {
                      firstname : "",
                      lastname : "",

                    }
                  if(item.inspectorId == null){
                      item.inspectorId = inspectorInfo;
                    }

          // if (typeof _package.text !== "undefined") {
          //   selectPackage = _package.text;
          // }

          // if (typeof item.package.text !== "undefined") {
          //   selectPackage = item.package.text;
          // }
          console.log( item.inspectorId );


          return {
            id: item._id,
            date: item.updatedAt.toString().substring(0, 10),
            time: item.updatedAt.toString().substring(19, 11),

            caseNo: item.caseNo,
                    runNo: item.runNo,
                    inspector_name: item.inspectorId.memberId.firstname + " " + item.inspectorId.memberId.lastname,
                    commission: item.commission,

            //feedback: "",
            //selectPackage: selectPackage,
            //status: item.caseStatus.stateStatus,
            //_package: _package,
          };
        });
    },
  },
  watch: {
    toggle_exclusive: {
      handler() {
        //console.log(val);
        console.log(this.toggle_exclusive);
      },
    },
    
    
  },
};
</script>
<style scope>
/* table {
  width: 100%;
}

table td,
table th {
  border: 1px solid;
} */
th {
  text-align: center !important;
}

#financeReport tr:nth-child(2n-1) {
  background-color: #e3f2fd;
}
tfoot td {
  padding: 0 16px;
}
tfoot td {
  background-color: #0d47a1;
  color: #ffffff;
}
</style>
