<template>
  <div class="text-left">
    <v-bottom-navigation v-model="value" :background-color="color" dark shift>
      <v-btn @click="redirectTo('/inspector/dashboard')">
        <span>Dashboard</span>

        <v-icon>mdi-desktop-mac-dashboard </v-icon>
      </v-btn>
      <v-btn @click="redirectTo('/inspector/report')">
        <span>Report</span>

        <v-icon>mdi-chart-line-variant </v-icon>
      </v-btn>

      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn color="primary" dark v-bind="attrs" v-on="on">
            <span>Users</span>
            <v-icon>mdi-account-edit</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item v-for="(item, index) in menuItem" :key="index">
            <v-btn block depressed color="secondary" @click="redirectTo(item.path)">{{
              item.title
            }}</v-btn>
          </v-list-item>
        </v-list>
      </v-menu>

      <v-spacer></v-spacer>
      <v-btn @click="logout">
        <span>Logout</span>

        <v-icon>mdi-logout-variant</v-icon>
      </v-btn>
    </v-bottom-navigation>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "InspectorMenu",
  data() {
    return {
      color: "primary",
      menuItem: [
        {
          title: "Profile",
          path: "/inspector/profile",
        },
      ],
      menuReportItem: [

        {
          title: "Report",
          path: "/inspector/report",
        },

      ],
    };
  },

  computed: {
    ...mapGetters({ getAuth: "BFC/getAuth" }),
  },
  mounted() {
    console.log("mounted menu");
    console.log(this.$cookies.get("Auth").authType);
    if (this.$cookies.get("Auth").authType != "INSPECTOR") {
      this.logout();
    }
  },
  methods: {
    ...mapActions({
      signout: "BFC/logout",
    }),

    redirectTo(path) {
      this.$router.push(path);
    },

    logout() {
      let redirectPath = "";
      //let auth = this.getAuth;

      redirectPath = "/inspector";
      this.$cookies.set("Auth", {});

      this.signout();
      this.$router.push(redirectPath);
    },
  },
};
</script>
